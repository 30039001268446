import React, { useEffect } from 'react';

import Loader from '@Components/design-system/loader';
export default function Home() {
  useEffect(() => {
    window.location.href = 'https://www.allohealth.care/';
  }, []);

  return (
    <>
      <div className='flex flex-1 justify-center'>
        <Loader size={3} />
      </div>
    </>
  );
}
